import React, {useState, useEffect, useContext} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js'
import {AuthContext} from '../../components/AuthContext.js'
import { CheckCircle } from "lucide-react"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const delay = ms => new Promise(res => setTimeout(res, ms));


const PaymentForm = () => {
    const { checkAuthAfterLogin } = useContext(AuthContext);
  const stripe = useStripe()
  const elements = useElements()
  const [paymentLoading, setPaymentLoading] = useState(false)

  const handleSubmit = async e => {
    if(e.preventDefault) e.preventDefault()
    if(!stripe || !elements) return
    setPaymentLoading(true)
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    })
    if(error) alert(error.message)
    await delay(6000)
    await checkAuthAfterLogin()
    window.location.replace('/presets')
    setPaymentLoading(false)
  }

  // useEffect(() => {
  //   if(stripe && elements) {
  //     handleSubmit({preventDefault: () => {}})
  //   }
  // }, [stripe, elements])

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || paymentLoading}
        className={`cool_button py-3 px-6 rounded-full text-lg font-bold mt-4 ${paymentLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {paymentLoading ? 'Processing...' : 'Pay And Get Access'}
      </button>
    </form>
  )
}

const MembershipPaymentPage = ({logout}) => {
  const {user} = useContext(AuthContext)
  const [clientSecret, setClientSecret] = useState(null)
  const API_BASE_URL =
    window.location.hostname === 'localhost'
      ? process.env.REACT_APP_LOCAL_API
      : process.env.REACT_APP_PUBLIC_API

  const handlePayment = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/create-payment-intent`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userId: user.id})
      })
      const data = await response.json()
      if(response.ok && data.clientSecret) {
        setClientSecret(data.clientSecret)
      }
      else {
        alert('Failed to initiate payment. Please try again.')
      }
    }
    catch(err) {
      console.error('Payment error:', err)
      alert('An error occurred. Please try again later.')
    }
  }

  useEffect(() => {
    handlePayment()
  }, [])

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <main className="flex flex-col items-center justify-center text-center px-4 relative z-10 w-full max-w-xl">
        <div className="bg-white rounded-xl p-6 space-y-2">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Last Thing, Unlock Your Membership</h1>
          </div>

          <div className="bg-purple-50 rounded-lg p-6">
            <div className="flex justify-center items-center mb-4">
              <span className="text-3xl font-bold text-purple-800">$9.99</span>
              <span className="text-gray-600 ml-2">AUD</span>
            </div>
            <ul className="space-y-2">
              {["Pay once, access forever", "View all current & future presets "].map(
                (benefit, index) => (
                  <li key={index} className="flex items-center text-gray-700">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                    {benefit}
                  </li>
                ),
              )}
            </ul>
          </div>

        {clientSecret ? (
          <Elements options={{clientSecret}} stripe={stripePromise}>
            <PaymentForm />
          </Elements>
        )
        : (
          <p>Loading...</p>
        )}

        </div>

      </main>
    </div>
  )
}

export default MembershipPaymentPage
