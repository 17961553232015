import React, { useEffect, useState } from "react";
import {
  fetchPresets,
  fetchPresetById,
  fetchPresetByIdRaw,
  createPreset,
  updatePreset,
  deletePreset,
  uploadImage,
  fetchFurniture,
  fetchFurnitureById,
  createFurniture,
  updateFurniture,
  deleteFurniture,
} from "./api"
import LoadingAnimation from "../../components/LoadingAnimation"
import PresetModal from "./PresetModal"
import FurnitureModal from "./FurnitureModal"

function LivingRoomPresets() {
  const [activeTab, setActiveTab] = useState('presets')
  const [modalLoading, setModalLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingPresets, setLoadingPresets] = useState(true);

  const [presets, setPresets] = useState([])
  const [showPresetModal, setShowPresetModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentPreset, setCurrentPreset] = useState({
    name: "",
    image: null,
    furniture: [],
  });

  // Furniture State
  const [furniture, setFurniture] = useState([]);
  const [loadingFurniture, setLoadingFurniture] = useState(false); // Set to false initially
  const [modalLoadingFurniture, setModalLoadingFurniture] = useState(false);
  const [showFurnitureModal, setShowFurnitureModal] = useState(false);
  const [isEditingFurniture, setIsEditingFurniture] = useState(false);
  const [currentFurniture, setCurrentFurniture] = useState({
    name: "",
    description: "",
    image_url: "",
    category: "",
    price: "",
    imageUploading: false, // For image upload status
  });
  const [furnitureCodeFilter, setFurnitureCodeFilter] = useState(null)


  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const data = await fetchPresets();
  //       setPresets(data);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (activeTab === 'presets') {
      (async () => {
        try {
          setLoadingPresets(true);
          const data = await fetchPresets();
          setPresets(data);
        } catch (err) {
          console.error("Error fetching presets:", err);
          alert("Failed to fetch presets. Please try again.");
        } finally {
          setLoadingPresets(false);
        }
      })();
    }
  }, [activeTab]);

  // Fetch Furniture
  useEffect(() => {
    if (activeTab === 'furniture') {
      (async () => {
        try {
          setLoadingFurniture(true);
          const data = await fetchFurniture();
          setFurniture(data);
        } catch (err) {
          console.error("Error fetching furniture:", err);
          alert("Failed to fetch furniture. Please try again.");
        } finally {
          setLoadingFurniture(false);
        }
      })();
    }
  }, [activeTab]);

  const handleOpenNew = () => {
    setCurrentPreset({
      name: "",
      image: null,
      furniture: [],
    });
    setIsEditingFurniture(false);
    setIsEditing(false)
    setShowPresetModal(true);
  }

  const handleOpenEdit = async (presetId) => {
    try {
      setModalLoading(true);
      setShowPresetModal(true);
      const presetData = await fetchPresetByIdRaw(presetId);
      console.log('presetData: ', presetData)
      setCurrentPreset(presetData);
      setIsEditing(true);
    } catch (err) {
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  }

  const handleSubmitPreset = async () => {
    try {
      setModalLoading(true);

      // Prepare the data to be sent to the API
      const presetData = {
        name: currentPreset.name,
        imageUrl: currentPreset.image, // Already uploaded image URL
        category: currentPreset.category,
        furniture: JSON.stringify(currentPreset.furniture), // Send only furniture IDs
      };

      // return console.log(currentPreset.furniture.map((f) => f.id))

      if (isEditing) {
        await updatePreset(currentPreset.id, presetData); // Update existing preset
      } else {
        await createPreset(presetData); // Create a new preset
      }

      setShowPresetModal(false);

      // Refresh the presets list after successful save
      const updatedPresets = await fetchPresets();
      setPresets(updatedPresets);

    } catch (err) {
      console.error("Error saving preset:", err);
    } finally {
      setModalLoading(false);
    }
  }

  const handleAddFurniture = () => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: [
        ...prevPreset.furniture,
        {
          id: Date.now(),
          CODE: "",
          "PRODUCT NAME": "",
          "FINISH/COLOUR": "",
          SUPPLIER: "",
          LENGTH: 0,
          HEIGHT: 0,
          DEPTH: 0,
          COST: 0,
          URL: "",
          "DATE ADDED": new Date().toISOString().split("T")[0],
          "CONFIRMED ONLINE": false,
        },
      ],
    }));
  }

  const handleRemoveFurniture = (fid) => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: prevPreset.furniture.filter((f) => f.id !== fid),
    }));
  }

  const handleFurnitureChange = (fid, key, value) => {
    setCurrentPreset((prevPreset) => ({
      ...prevPreset,
      furniture: prevPreset.furniture.map((f) =>
        f.id === fid ? { ...f, [key]: value } : f
      ),
    }));
  }

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentPreset((prev) => ({
        ...prev,
        image: null,  // Clear current image
        imagePreview: null,  // Clear preview
        imageUploading: true,  // Show loading animation
      }));

      try {
        const result = await uploadImage(file);

        setCurrentPreset((prev) => ({
          ...prev,
          image: result.imageUrl,  // Save uploaded image URL
          imagePreview: result.imageUrl,  // Display image
          imageUploading: false,  // Hide loading indicator
        }));
      } catch (err) {
        console.error("Error uploading image:", err);
        setCurrentPreset((prev) => ({
          ...prev,
          imageUploading: false,  // Hide loading indicator
        }));
        alert("Failed to upload image. Please try again.");
      }
    }
  }

  const handleSubmitFurniture = async () => {
    try {
      setModalLoadingFurniture(true);

      // Collect and parse all fields from `currentFurniture`
      const furnitureData = {
        code: currentFurniture.code || "",
        product_name: currentFurniture.product_name || "",
        finish_colour: currentFurniture.finish_colour || "",
        supplier: currentFurniture.supplier || "",
        length: currentFurniture.length ? parseFloat(currentFurniture.length) : null,
        height: currentFurniture.height ? parseFloat(currentFurniture.height) : null,
        depth: currentFurniture.depth ? parseFloat(currentFurniture.depth) : null,
        cost: currentFurniture.cost ? parseFloat(currentFurniture.cost) : null,
        url: currentFurniture.url || "",
        image_url: currentFurniture.image_url || "",
      };

      console.log("Furniture Data to Save:", furnitureData);
      console.log("isEditingFurniture:", isEditingFurniture);

      // Call the appropriate API based on the mode (editing or creating)
      if (isEditingFurniture) {
        await updateFurniture(currentFurniture.id, furnitureData); // Update existing furniture
      } else {
        await createFurniture(furnitureData); // Create new furniture
      }

      // Close the modal
      setShowFurnitureModal(false);

      // Refresh the furniture list after a successful save
      const updatedFurniture = await fetchFurniture();
      setFurniture(updatedFurniture);

    } catch (err) {
      console.error("Error saving furniture:", err);
    } finally {
      setModalLoadingFurniture(false);
    }
  }


  const handleChangeFurnitureImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentFurniture((prev) => ({
        ...prev,
        image_url: null,  // Clear current image
        imageUploading: true,  // Show loading animation
      }));

      try {
        const result = await uploadImage(file);

        setCurrentFurniture((prev) => ({
          ...prev,
          image_url: result.imageUrl,  // Save uploaded image URL
          imageUploading: false,  // Hide loading indicator
        }));
      } catch (err) {
        console.error("Error uploading image:", err);
        setCurrentFurniture((prev) => ({
          ...prev,
          imageUploading: false,  // Hide loading indicator
        }));
        alert("Failed to upload image. Please try again.");
      }
    }
  }

  const handleOpenFurnitureNew = () => {
    setCurrentFurniture({
      name: "",
      description: "",
      image_url: "",
      category: "",
      price: "",
    });
    setIsEditingFurniture(false);
    setShowFurnitureModal(true);
  }

  const handleOpenFurnitureEdit = (id) => {
    const selectedFurniture = furniture.find((item) => item.id === id);
    if (selectedFurniture) {
      setCurrentFurniture(selectedFurniture); // Set the selected furniture item for editing
      setShowFurnitureModal(true); // Open the modal
      setIsEditingFurniture(true); // Set editing mode
    }
  }

  const handleDeletePreset = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this preset? This action cannot be undone."
    );

    if (!confirmDelete) return; // Exit if user cancels

    try {
      setModalLoading(true);

      // Call the deletePreset API
      await deletePreset(id);

      // Close the modal if open
      setShowPresetModal(false);

      // Refresh the presets list
      const updatedPresets = await fetchPresets();
      setPresets(updatedPresets);
    } catch (err) {
      console.error("Error deleting preset:", err.message);
      alert("Failed to delete preset. Please try again.");
    } finally {
      setModalLoading(false);
    }
  }

  const handleDeleteFurniture = async (id) => {
    try {
       setModalLoadingFurniture(true);
       await deleteFurniture(id);
        setShowFurnitureModal(false);

        // Refresh the furniture list after a successful save
        const updatedFurniture = await fetchFurniture();
        setFurniture(updatedFurniture);
        setModalLoadingFurniture(false);
    } catch (err) {
      console.error("Error deleting furniture:", err.message);
       setModalLoadingFurniture(false);
    }
  }

  const furnitureButtonClasses = prefix => {
    const active = furnitureCodeFilter === prefix
    return `text-md font-semibold border border-2 rounded-full px-2 py-1 less_cool_button_with_shadow_no_hover ${active ? "bg-purple-300 cursor-default" : "border-black cursor-pointer"}`
  }


  const presets_tab = () => {
    if(loadingPresets || loadingFurniture) return null
    if(activeTab == 'presets') return (
      <div className="mx-auto max-w-7xl p-4">
        <h1 className="text-3xl font-bold mb-6 inline">Living Room Presets ({presets?.length})</h1>

        <button
          onClick={handleOpenNew}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1 px-4 rounded ml-4"
        >
          + Add
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6">
          {presets.map((preset) => (
            <div
              key={preset.id}
              className="border rounded-lg shadow hover:shadow-lg transition-shadow cursor-pointer overflow-hidden"
              onClick={() => handleOpenEdit(preset.id)}
            >
              <img
                //src={preset.image}
                     src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/320x320/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                     srcSet={`
                       https://dpi84emh0ku5w.cloudfront.net/fit-in/480x480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 320w,
                       https://dpi84emh0ku5w.cloudfront.net/fit-in/768x768/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 480w,
                       https://dpi84emh0ku5w.cloudfront.net/fit-in/1024x1024/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 768w
                        https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1024w
                        https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1480w
                     `}
                     sizes="(max-width: 320px) 320px, (max-width: 480px) 480px, (max-width: 768px) 768px, 1024px"
                alt={preset.name}
                className="w-full h-48 object-contain"
              />
              <div className="p-4">
                <p className="font-semibold text-lg">{preset.name}</p>
              </div>
            </div>
          ))}
        </div>

        <PresetModal
          isOpen={showPresetModal}
          onClose={() => setShowPresetModal(false)}
          preset={currentPreset}
          setPreset={setCurrentPreset}
          onSubmit={handleSubmitPreset}
          isEditing={isEditing}
          loading={modalLoading}
          onAddFurniture={handleAddFurniture}
          onRemoveFurniture={handleRemoveFurniture}
          onFurnitureChange={handleFurnitureChange}
          onImageChange={handleChangeImage}
          onDelete={handleDeletePreset}
        />
      </div>
    )
    return null
  }

  const furniture_tab = () => {
    if (loadingPresets || loadingFurniture) return null;
    if (activeTab != "furniture") return null

      const filteredFurniture = furnitureCodeFilter
        ? furniture.filter(item => item.code.slice(0, 2) === furnitureCodeFilter)
        : furniture
    
    return (
        <div className="w-full p-4">
          <h1 className="text-3xl font-bold mb-6 inline">Furniture</h1>

          <button
            onClick={handleOpenFurnitureNew}
            className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-1 px-2 rounded ml-2"
          >
            + Add
          </button>

          <div className="flex flex-wrap gap-2 mb-4 mt-4">
            <button
              onClick={() => setFurnitureCodeFilter(null)}
              className={furnitureButtonClasses(null)}
              disabled={furnitureCodeFilter === null}
            >
              All Codes
            </button>
            {[...new Set(furniture.map(item => item.code.slice(0, 2)))].sort()
              .map(prefix => (
                <button
                  key={prefix}
                  onClick={() => setFurnitureCodeFilter(prefix)}
                  className={furnitureButtonClasses(prefix)}
                  disabled={furnitureCodeFilter === prefix}
                >
                  {prefix}
                </button>
              ))}
          </div>

          <div className="mt-6">
            {filteredFurniture.length === 0 ? (
              <p className="text-gray-600">No furniture items found.</p>
            ) : (
              <div className="space-y-6">
                {filteredFurniture.sort( (a, b) => new Date(b.added_on) - new Date(a.added_on))?.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center gap-4 p-4 border rounded-lg shadow hover:shadow-lg transition-shadow cursor-pointer"
                    onClick={() => handleOpenFurnitureEdit(item.id)}
                  >
                    <img
                      //src={item.image_url}
                      src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/140x140/${item.image_url?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                      alt={item.product_name}
                      className="w-24 h-24 object-contain cursor-pointer"
                    />

                    {/* Furniture Details */}
                    <div className="flex-1">
                      <p className="font-semibold text-lg">{item.product_name}</p>
                      <p className="text-gray-600">Code: {item.code}</p>
                      <p className="text-gray-600">Supplier: {item.supplier}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <FurnitureModal
            isOpen={showFurnitureModal}
            onClose={() => setShowFurnitureModal(false)}
            furnitureItem={currentFurniture}
            setFurnitureItem={setCurrentFurniture}
            onSubmit={handleSubmitFurniture}
            isEditing={isEditingFurniture}
            loading={modalLoadingFurniture}
            onImageChange={handleChangeFurnitureImage}
            onDelete={handleDeleteFurniture}
          />
        </div>
      );
    return null;
  };


  return (
    <div className="p-4">

    <div className="max-w-md mx-auto mt-10">
      <div className="flex justify-center">
        <button
          className={`py-2 px-6 text-sm font-medium rounded-l-lg border-2 border-purple-500 ${
            activeTab === 'presets'
              ? 'bg-purple-500 text-white'
              : 'text-gray-500 hover:text-purple-500'
          }`}
          onClick={() => setActiveTab('presets')}
        >
          Presets
        </button>
        <button
          className={`py-2 px-6 text-sm font-medium rounded-r-lg border-2 border-purple-500 ${
            activeTab === 'furniture'
              ? 'bg-purple-500 text-white'
              : 'text-gray-500 hover:text-purple-500'
          }`}
          onClick={() => setActiveTab('furniture')}
        >
          Furniture
        </button>
      </div>
    </div>


      {(loadingPresets || loadingFurniture) && <div className="w-full mt-24" ><LoadingAnimation /></div>}


      {presets_tab()}
      {furniture_tab()}
    </div>
  );
}

export default LivingRoomPresets