import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Settings, Target, Menu } from "lucide-react";
import { AuthContext } from "./components/AuthContext.js"; // Adjust the path as necessary
import { useState } from "react";

export default function Layout({ children }) {
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isActive = (path) => {
    if (path == "/profile" && location.pathname === path)
      return { background: "black", color: "white" };
    return location.pathname === path
      ? { background: "#ff5051", color: "white" }
      : {};
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const settings = () => {
    const closeDropdown = () => setIsOpen(false);
    return (
      <div className="relative flex">
        <div
          className={`px-3 py-1 ${isOpen ? 'bg-[#eed0b1]' : 'bg-[#e5dfd9] hover:bg-[#efe2d5]'} rounded-lg cursor-pointer`}
          onClick={toggleDropdown}
        >
          <Settings className="h-8 hover:rotate-180 transition-transform duration-[1s]" color="black" />
        </div>

        {isOpen && (
          <div className="absolute right-0 mt-12 bg-white shadow-lg rounded-lg w-40 z-40">
            <Link
              to="/profile"
              className="block px-4 py-2 text-black hover:bg-gray-200 rounded-t-lg"
              onClick={closeDropdown}
            >
              Profile
            </Link>
            <Link
              to="/contact"
              className="block px-4 py-2 text-black hover:bg-gray-200"
              onClick={closeDropdown}
            >
              Contact
            </Link>
            <p
              className="block px-4 py-2 text-black hover:bg-gray-200 rounded-b-lg cursor-pointer hover:bg-red-100"
              onClick={logout}
            >
              Logout
            </p>
          </div>
        )}
      </div>
    )
  }

  const header = () => {
    if (location.pathname === "/login" || location.pathname === "/join" || location.pathname === "/confirm" || location.pathname.includes('reset-password')) return null;

    return (
      <header className="w-full bg-[#f9f6f3]">
        <div className="mx-auto px-4 h-16 flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center rounded-xl hover:scale-105 transition duration-700 ease-in-out">
            <h4 className="hidden md:flex ml-2 font-bold text-2xl DeliciousHandrawn"><span className="text-purple-500">Room</span> <span className="text-purple-900">Customiser</span></h4>
            <h4 className="flex md:hidden ml-2 font-bold text-2xl DeliciousHandrawn"><span className="text-purple-500">R</span> <span className="text-purple-900">C</span></h4>
          </Link>

          {/* Desktop Nav */}
          <nav className="hidden md:flex space-x-4 justify-center items-center flex-grow">
            {user?.id ? (
              <Link
                to="/presets"
                className={`flex items-center px-3 py-1 ${location.pathname == "/presets" ? 'bg-[#eed0b1] font-medium' : 'bg-[#e5dfd9]'} rounded-lg cursor-pointer`}
              >
                Interiors
              </Link>
            ) : null}
          </nav>

          {/* Right-side Buttons */}
          <div className="hidden md:flex gap-4">
            {user?.id ? (
              <>
              {settings()}
              </>
            ) : (
              <>
                <button
                  className="cool_button py-2 px-6 rounded-full relative"
                  onClick={() => (window.location.href = "/join")}
                >
                  Unlock All Designs Now!
                </button>
                <button
                  className="less_cool_button py-2 px-4 rounded-full relative"
                  onClick={() => (window.location.href = "/login")}
                >
                  Login
                </button>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button className="flex md:hidden">
          <div className="flex gap-3">
            {user?.id ? (
              <>
              {settings()}
              </>
            ) : (
              <>
                <button
                  className="cool_button py-2 px-6 rounded-full relative text-md"
                  onClick={() => (window.location.href = "/join")}
                >
                  Join
                </button>
                <button
                  className="less_cool_button py-2 px-4 rounded-full relative text-md"
                  onClick={() => (window.location.href = "/login")}
                >
                  Login
                </button>
              </>
            )}
          </div>
            {/*<Menu className="h-8 w-auto mt-1" onClick={toggleMenu}/>*/}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-[#f9f6f3] flex flex-col items-center py-4 space-y-4">
            {user?.id ? (
              <Link
                to="/presets"
                className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100"
                style={isActive("/presets")}
              >
                <Target className="h-4 w-4 mr-2" /> Presets
              </Link>
            ) : null}

            {user?.id ? (
              <Link
                to="/profile"
                className="px-3 py-1 text-black hover:text-white hover:bg-black rounded-lg cursor-pointer"
                style={isActive("/profile")}
              >
                Settings
              </Link>
            ) : (
              <>
                <button
                  className="cool_button py-2 px-6 rounded-full relative"
                  onClick={() => (window.location.href = "/join")}
                >
                  Unlock All Designs Now!
                </button>
                <button
                  className="less_cool_button py-2 px-4 rounded-full relative"
                  onClick={() => (window.location.href = "/login")}
                >
                  Login
                </button>
              </>
            )}
          </div>
        )}
      </header>
    );
  };

  return (
    <div className="min-h-screen bg-background flex flex-col">
      {header()}
      {children}
    </div>
  );
}