import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom'
import { Check, Heart, ChevronLeft, Star, Ruler, ChevronDown, Settings, CreditCard, FileText, LogOut } from "lucide-react"
import { AuthContext } from '../components/AuthContext.js'
import LoadingScreen from '../components/LoadingScreen'
import Footer from "../components/Footer.js";

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

function LivingRoomDesign() {
  const { user } = useContext(AuthContext)
  const { presetId } = useParams()
  const [loading, setLoading] = useState(true)
  const [preset, setPreset] = useState({})
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('presets')

  useEffect(() => {
      window.scrollTo(0, 0)
  }, [60])

  const fetchPreset = async (presetId) => {
    setLoading(true)
    const res = await fetch(baseDomain+"/api/presets/preview/"+presetId)
    if (!res.ok) {
      throw new Error("Failed to fetch presets");
    }
    const x = await res.json()
    console.log(x)
    setPreset(x)
    setLoading(false)
  }

  useEffect(() => {
    fetchPreset(presetId)
  }, [presetId, user?.id])

  if(loading) return <LoadingScreen/>


  return (
    <div className="bg-[#f9f6f3] flex flex-col">
        {/* Header */}
      <div className="flex justify-between items-center w-full px-6 pb-2">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
      </div>

        <div className="min-h-[80vh] md:w-full p-4 flex flex-col md:flex-row justify-center items-center">
          <div className="w-full md:w-1/2 flex-col justify-center">
            <img src={preset.image} alt="Living Room Design" className="w-full max-w-3xl object-cover rounded-lg" />
            <h1 className="text-2xl font-bold mb-2 text-center pt-8">{preset.name}</h1>
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:text-left p-8">
      <div className="flex flex-wrap justify-center gap-8 w-full">
        <div className="max-w-md">
          <div className="less_cool_button_with_shadow rounded-lg border bg-white text-gray-900 shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-3xl font-semibold leading-none tracking-tight">Furniture list is hidden 😞</h3>
              <p className="text-md text-gray-500">Join for the price of a flatwhite to see the design details. Pays for itself in furniture cost and time savings!</p>
            </div>
            <div className="p-6 pt-0 grid gap-4">
              <div className="flex items-baseline justify-center gap-1">
                <span className="text-4xl font-bold">$9.99</span>
                <span className="text-gray-500">AUD</span>
              </div>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all current products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all future products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Regular updates and new features</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Time-saving designs</span>
                </li>
              </ul>
            </div>
            <div className="flex items-center p-6 pt-0">
              <style jsx>{`
                .grow-shrink {
                  animation: growShrink 1.8s ease-in-out infinite;
                  backface-visibility: hidden;
                  transform: translateZ(0);
                }

                @keyframes growShrink {
                  0%, 100% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.05);
                  }
                }

                /* Ensure text remains sharp */
                .cool_button {
                  font-smooth: antialiased;
                  /* Add any other necessary styles */
                }

                /* Optional: Reduce motion for accessibility */
                @media (prefers-reduced-motion: reduce) {
                  .grow-shrink {
                    animation: none;
                  }
                }
              `}</style>
              <button
                className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative grow-shrink will-change-transform"
                onClick={() => window.location.href = '/join'}
              >
                Unlock Design Details Now!
              </button>
            </div>
          </div>
        </div>
      </div>
          </div>


        </div>
  

      <div className="p-4 mt-4">
        <div className="overflow-x-auto px-2 md:px-4 lg:px-12">
          <h3 className="text-2xl font-semibold mb-2 pt-8">Furniture List:</h3>

          {preset.furniture?.map((item) => (
            <div
              key={item.id}
              className="relative flex flex-col md:flex-row items-start sm:items-center gap-4 mb-4 p-4 border rounded-lg shadow hover:shadow-lg transition-shadow bg-white"
            >
              <div className="absolute inset-0 bg-white bg-opacity-20 z-10 flex items-center justify-center">
                <span className="text-xl font-semibold text-gray-800 border border-2 rounded-xl border-black px-2 py-2 less_cool_button_with_shadow_no_hover">Furniture is Hidden</span>
              </div>
              {/* Quantity and Image Container */}
              <div className="flex items-center space-x-2 w-full md:w-auto">
                {/* Quantity */}
              <div className="inline flex items-center justify-center md:justify-left w-full blur-md">
                {item.quantity && (
                  <span className="text-lg font-bold text-gray-800">
                    {item.quantity}x
                  </span>
                )}
                </div>
              </div>

              {/* Furniture Details */}
              <div className="w-full flex-1 text-center md:text-left blur-md">
                <p className="font-semibold text-lg">{item.product_name}</p>
                <p className="text-gray-600">Supplier: {item.supplier}</p>

                {/* Additional Details */}
                <div className="mt-2 space-y-1">
                    <p className="text-gray-700">Height: 69 cm</p>
                    <p className="text-gray-700">Length: 69 cm</p>
                    <p className="text-gray-700">Depth: 69 cm</p>
                  {item.finish_colour && (
                    <p className="text-gray-700">
                      Finish Colour: {item.finish_colour}
                    </p>
                  )}
                </div>
              </div>

              {/* Order Link and Cost */}
              <div className="flex flex-col items-center md:items-start mt-4 sm:mt-0 w-full md:w-auto blur-md">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block cursor-pointer"
                  aria-label={`Order ${item.product_name}`}
                >
                  Link To Order Item
                </a>
                {/* Cost */}
                {item.cost && (
                  <div className="w-full text-center">
                  <p className="mt-4 text-gray-700">
                    Item Price: ${Number(item.cost).toFixed(2)}
                  </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer top_color={'#f9f6f3'}/>


    </div>
  )
}

export default LivingRoomDesign