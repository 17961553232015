import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Heart,
  ChevronLeft,
  Star,
  Ruler,
  ChevronDown,
  Settings,
  CreditCard,
  FileText,
  LogOut,
} from 'lucide-react';

import { AuthContext } from './components/AuthContext.js';
import LoadingScreen from './components/LoadingScreen';
import Floorplan from './components/Floorplan';
import Footer from './components/Footer';


const baseDomain =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;

function LivingRoomDesign() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  const { presetId } = useParams();
  const [loading, setLoading] = useState(true);
  const [preset, setPreset] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('presets');

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPreset();
    // Corrected dependency array to include 'presetId'
  }, [presetId]);

  const fetchPreset = async () => {
    try {
      setLoading(true);
      console.log(`${baseDomain}/api/presets/${presetId}`);
      const res = await fetch(`${baseDomain}/api/presets/${presetId}`);
      if (!res.ok) {
        console.error('Failed to fetch presets:', res);
        throw new Error('Failed to fetch presets');
      }
      const data = await res.json();
      console.log('Fetched preset:', data);
      setPreset(data);
    } catch (error) {
      console.error('Error fetching preset:', error);
      // Optionally, handle the error by showing a message to the user
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingScreen />;

  return (
    <>
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col">
      {/* Header */}
      <header className="flex justify-between items-center w-full p-6">
        <a href="/presets" className="text-purple-800 hover:underline">
          &larr; Back
        </a>
      </header>

      {/* Preset Image and Title */}
      <div className="md:w-full p-4 flex flex-col justify-center items-center">
        <img
          src={preset.image}
          alt="Living Room Design"
          className="w-full h-full max-w-4xl object-cover rounded-lg"
        />
        <h1 className="text-2xl font-bold mb-2 text-center pt-8">
          {preset.name}
        </h1>
      </div>

      {/* Furniture List */}
      <div className="p-4 mt-4">
        <div className="overflow-x-auto px-2 md:px-4 lg:px-12">
          <h3 className="text-2xl font-semibold mb-2 pt-8">Furniture List:</h3>

          {preset.furniture?.map((item) => (
            <div
              key={item.id}
              className="flex flex-col md:flex-row items-start sm:items-center gap-4 mb-4 p-4 border rounded-lg shadow hover:shadow-lg transition-shadow bg-white"
            >
              {/* Quantity and Image Container */}
              <div className="flex items-center space-x-2 w-full md:w-auto">
                {/* Quantity */}
              <div className="inline flex items-center justify-center md:justify-left w-full">
                {item.quantity && (
                  <span className="text-lg font-bold text-gray-800">
                    {item.quantity}x
                  </span>
                )}
                {/* Image */}
                <img
                  src={item.image_url || '/path/to/placeholder-image.png'}
                  alt={`${item.product_name} image`}
                  className="w-24 h-24 object-contain cursor-pointer"
                  onError={(e) => {
                    e.target.src = '/path/to/placeholder-image.png';
                  }}
                />
                </div>
              </div>

              {/* Furniture Details */}
              <div className="w-full flex-1 text-center md:text-left">
                <p className="font-semibold text-lg">{item.product_name}</p>
                <p className="text-gray-600">Supplier: {item.supplier}</p>

                {/* Additional Details */}
                <div className="mt-2 space-y-1">
                  {item.height && (
                    <p className="text-gray-700">Height: {item.height} cm</p>
                  )}
                  {item.length && (
                    <p className="text-gray-700">Length: {item.length} cm</p>
                  )}
                  {item.depth && (
                    <p className="text-gray-700">Depth: {item.depth} cm</p>
                  )}
                  {item.finish_colour && (
                    <p className="text-gray-700">
                      Finish Colour: {item.finish_colour}
                    </p>
                  )}
                </div>
              </div>

              {/* Order Link and Cost */}
              <div className="flex flex-col items-center md:items-start mt-4 sm:mt-0 w-full md:w-auto">
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block cursor-pointer"
                  aria-label={`Order ${item.product_name}`}
                >
                  Link To Order Item
                </a>
                {/* Cost */}
                {item.cost && (
                  <div className="w-full text-center">
                  <p className="mt-4 text-gray-700">
                    Item Price: ${Number(item.cost).toFixed(2)}
                  </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*<Floorplan furnitureList={preset.furniture} />*/}

    </div>
    <Footer top_color={'#f3f4f6'}/>
    </>
  );
}

export default LivingRoomDesign;
