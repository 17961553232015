import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { AuthContext } from '../../components/AuthContext'; // Adjust the path as necessary
import { Check } from 'lucide-react';

const designOptions = [
  { id: 1, name: 'Contemporary Chic', image: '/assets/LT01.png' },
  { id: 2, name: 'Rustic Warmth', image: '/assets/LT02.png' },
  { id: 3, name: 'Minimalist Zen', image: '/assets/VS05.png' },
  { id: 4, name: 'Modern Elegance', image: '/assets/VS02.png' }, // Corrected duplicate name
];

const API_BASE_URL =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isForgotPassword, setIsForgotPassword] = useState(false) // Toggle state for forgot password

  const { user, setUser, checkAuthAfterLogin } = useContext(AuthContext)
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) navigate('/presets')
  }, [user, navigate])

  const handleLogin = async (e) => {
    e.preventDefault()
    setError('')
    setIsLoading(true)

    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        await checkAuthAfterLogin()
        window.location.href = '/presets'
      } else {
        setError(data.error || 'Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true); // Show the reset password form
  }

  const handleRequestResetPassword = async () => {
    setError('')
    setIsLoading(true)

    try {
      const response = await fetch(`${API_BASE_URL}/reset-password-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setError('')
        alert(data.message || 'Check your email for the reset link.');
        setIsForgotPassword(false); // Hide the forgot password form after successful request
      } else {
        setError(data.error || 'Error sending password reset link.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      {/* Header */}
      <header className="absolute top-0 flex justify-between w-full p-6 max-w-7xl z-50">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center text-center px-4 relative z-10 w-full max-w-md">
        <h1 className="text-3xl md:text-5xl font-bold text-gray-900 leading-tight mb-2 md:mb-4">
          Welcome Back!
        </h1>
        <p className="text-lg md:text-xl text-gray-600 mb-8">
          Please log in to your account to continue.
        </p>

        {/* Login or Forgot Password Form */}
        {!isForgotPassword ? (
          <form
            onSubmit={handleLogin} // Attach the handleLogin function
            className="w-full bg-white p-6 rounded-lg shadow-md space-y-4"
          >
            {/* Email Field */}
            <div className="flex flex-col text-left">
              <label htmlFor="email" className="mb-1 text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="you@example.com"
              />
            </div>

            {/* Password Field */}
            <div className="flex flex-col text-left">
              <label htmlFor="password" className="mb-1 text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="••••••••"
              />
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-red-500 text-sm">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit" // Change type to submit
              className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              disabled={isLoading}
            >
              {isLoading ? 'Logging In...' : 'Log In'}
            </button>
          </form>
        ) : (
          <div className="w-full bg-white p-6 rounded-lg shadow-md space-y-4">
            {/* Email Field for Reset */}
            <div className="flex flex-col text-left">
              <label htmlFor="email" className="mb-1 text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="you@example.com"
              />
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-red-500 text-sm">
                {error}
              </div>
            )}

            <button
              type="button"
              onClick={handleRequestResetPassword}
              className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Request Password Reset'}
            </button>

            {/* Back to Login */}
            <button
              type="button"
              onClick={() => setIsForgotPassword(false)}
              className="w-full mt-4 text-center text-purple-600 hover:underline"
            >
              Back to Login
            </button>
          </div>
        )}

        {/* Forgot Password Link */}
        {!isForgotPassword && (
          <a
            href="#"
            onClick={handleForgotPassword}
            className="text-purple-600 hover:underline pt-4"
          >
            Forgot Your Password?
          </a>
        )}

        {/* Join Link */}
        <a href="/join" className="text-purple-800 hover:underline pt-2">
          Not a member? Join first!
        </a>
      </main>

      {/* Design Options - Optional */}
      <div className="w-[80vw] hidden md:flex z-0">
        {designOptions.map((item, index) => (
          <motion.div
            key={item.id}
            className="absolute w-60 h-auto"
            style={{
              top: `${10 + index * 18}%`,
              left: `${index % 2 === 0 ? '5%' : '80%'}`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.9,
              zIndex: 0,
            }}
            animate={{ y: [0, -15, 0] }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              delay: index * 0.5,
            }}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-full object-cover rounded-md"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default LoginPage;
