import React, { useRef, useState, useEffect } from "react"
import { uploadImage, fetchFurniture } from "./api"

const PresetModal = ({
  isOpen,
  onClose,
  preset,
  setPreset,
  onSubmit,
  isEditing,
  loading,
  onDelete,
}) => {
  const fileInputRef = useRef(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [furnitureList, setFurnitureList] = useState([])
  const [loadingFurniture, setLoadingFurniture] = useState(true)
  const [selectedCodePrefix, setSelectedCodePrefix] = useState(null)
  const [onlySelectedFilter, setOnlySelectedFilter] = useState(false)

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setLoadingFurniture(true)
          const data = await fetchFurniture()
          setFurnitureList(data)
        } catch (err) {
          console.error("Error fetching furniture:", err)
        } finally {
          setLoadingFurniture(false)
        }
      })()
    }
  }, [isOpen])

  const handleReplacePresetImage = async e => {
    const file = e.target.files[0]
    if (file) {
      setPreset(prev => ({ ...prev, image: null, imagePreview: null }))
      setImageUploading(true)
      try {
        const result = await uploadImage(file)
        setPreset(prev => ({
          ...prev,
          image: result.imageUrl,
          imagePreview: result.imageUrl,
        }))
      } catch (err) {
        console.error("Error uploading image:", err)
        alert("Failed to upload image. Please try again.")
      } finally {
        setImageUploading(false)
      }
    }
  }

  const toggleFurnitureSelection = furnitureItem => {
    setPreset(prev => {
      const validFurniture = prev.furniture?.filter(f =>
        furnitureList.some(listItem => listItem.id === f.id)
      )
      const isSelected = validFurniture?.some(f => f.id === furnitureItem.id)
      let updatedFurniture
      if (isSelected) {
        updatedFurniture = validFurniture.filter(f => f.id !== furnitureItem.id)
      } else {
        updatedFurniture = [...validFurniture, { id: furnitureItem.id, quantity: 1 }]
      }
      return { ...prev, furniture: updatedFurniture }
    })
  }

  const handleQuantityChange = (furnitureId, newQuantity) => {
    setPreset(prev => {
      const updatedFurniture = prev.furniture?.map(f =>
        f.id === furnitureId ? { ...f, quantity: newQuantity } : f
      )
      return { ...prev, furniture: updatedFurniture }
    })
  }

  const buttonClasses = isActive =>
    `text-md font-semibold border border-2 rounded-full px-2 py-1 less_cool_button_with_shadow_no_hover ${
      isActive ? "bg-purple-300 cursor-default" : "border-black cursor-pointer"
    }`

  const handleClose = () => {
    setSelectedCodePrefix(null)
    setOnlySelectedFilter(false)
    onClose()
  }

  if (!isOpen) return null
  if (preset.furniture?.length < 1) preset.furniture = []

  let filteredFurniture = furnitureList
  if (selectedCodePrefix) {
    filteredFurniture = filteredFurniture.filter(
      item => item.code.slice(0, 2) === selectedCodePrefix
    )
  }
  if (onlySelectedFilter) {
    filteredFurniture = filteredFurniture.filter(item =>
      preset.furniture?.some(f => f.id === item.id)
    )
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-md shadow-lg w-full max-w-7xl p-6 relative flex flex-col h-[95vh]">
        {loading ? (
          <div className="text-center py-6 w-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-lg font-semibold">Loading...</p>
          </div>
        ) : (
          <>
            <div className="flex flex-col md:flex-row gap-6 h-full">
              <div className="md:w-1/2">
                <div className="sticky top-0">
                  <div className="bg-gray-100 rounded-xl p-2">
                    <h2 className="text-2xl font-bold mb-4">
                      {isEditing ? "Edit Preset" : "Create a New Preset"}
                    </h2>
                    <label className="block font-semibold mb-1">Preset Name:</label>
                    <input
                      type="text"
                      className="w-full border rounded px-3 py-2 mb-4"
                      value={preset.name}
                      onChange={e =>
                        setPreset({ ...preset, name: e.target.value })
                      }
                    />
                    <label className="block font-semibold mb-1">Preset Image:</label>
                    {imageUploading ? (
                      <div className="flex justify-center items-center h-64 w-full border border-gray-300 rounded">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                        <p className="ml-4 text-blue-600 font-semibold">
                          Uploading...
                        </p>
                      </div>
                    ) : preset.imagePreview || preset.image ? (
                      <div className="flex flex-col items-center">
                        <img
                          src={preset.imagePreview || preset.image}
                          alt="Preset Preview"
                          className="mt-2 rounded border max-w-full h-64 object-contain"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          className="hidden"
                          onChange={handleReplacePresetImage}
                        />
                        <button
                          onClick={() => fileInputRef.current.click()}
                          className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded mt-4"
                        >
                          Replace Image
                        </button>
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleReplacePresetImage}
                        className="mb-4"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 flex flex-col" style={{height: 'calc(100% - 50px)'}} >
                <div className="sticky top-0 bg-white z-10">
                  <h3 className="text-xl font-semibold mb-4">
                    Furniture Items ({preset.furniture?.length || 0})
                  </h3>
                  <div className="flex flex-row flex-wrap justify-center pb-2 space-x-2 space-y-2">
                    <button
                      onClick={() => {
                        setSelectedCodePrefix(null)
                        setOnlySelectedFilter(false)
                      }}
                      className={buttonClasses(
                        !onlySelectedFilter && selectedCodePrefix === null
                      ) + " mt-2"}
                      disabled={!onlySelectedFilter && selectedCodePrefix === null}
                    >
                      All Codes
                    </button>
                    <button
                      onClick={() => {
                        setOnlySelectedFilter(true)
                        setSelectedCodePrefix(null)
                      }}
                      className={buttonClasses(onlySelectedFilter) + " mt-2"}
                      disabled={onlySelectedFilter}
                    >
                      All Selected
                    </button>
                    {[...new Set(furnitureList.map(item => item.code.slice(0, 2)))].sort()
                      .map(prefix => (
                        <button
                          key={prefix}
                          onClick={() => {
                            setSelectedCodePrefix(prefix)
                            setOnlySelectedFilter(false)
                          }}
                          className={buttonClasses(selectedCodePrefix === prefix) + " mt-2"}
                          disabled={selectedCodePrefix === prefix}
                        >
                          {prefix}
                        </button>
                      ))}
                  </div>
                </div>
                <div className="overflow-y-auto flex-1">
                  {loadingFurniture ? (
                    <p>Loading furniture...</p>
                  ) : (
                    <div className="space-y-4">
                      {filteredFurniture.map(item => {
                        const isSelected = preset.furniture?.some(
                          f => f.id === item.id
                        )
                        const furnitureObj = preset.furniture?.find(
                          f => f.id === item.id
                        )
                        return (
                          <div
                            key={item.id}
                            className={`flex items-center border rounded-lg p-4 shadow hover:shadow-lg transition-shadow cursor-pointer ${
                              isSelected
                                ? "bg-green-100 border-green-500"
                                : ""
                            }`}
                            onClick={() => toggleFurnitureSelection(item)}
                          >
                            <img
                              src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/140x140/${item.image_url?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                              className="w-24 h-24 object-contain rounded border mr-4"
                            />
                            <div className="flex-1">
                              <p className="font-semibold">{item.product_name}</p>
                              <p className="text-sm text-gray-600">Code: {item.code}</p>
                              <p className="text-sm text-gray-600">Supplier: {item.supplier}</p>
                            </div>
                            {isSelected && (
                              <div className="flex flex-col items-end">
                                <span className="text-green-600 font-bold">Selected</span>
                                <div className="mt-2 flex items-center">
                                  <button
                                    onClick={e => {
                                      e.stopPropagation()
                                      handleQuantityChange(
                                        item.id,
                                        Math.max(
                                          1,
                                          (furnitureObj.quantity || 1) - 1
                                        )
                                      )
                                    }}
                                    className="bg-gray-300 text-gray-700 px-2 py-1 rounded-l"
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    min="1"
                                    value={furnitureObj.quantity || 1}
                                    onChange={e =>
                                      handleQuantityChange(
                                        item.id,
                                        Math.max(1, parseInt(e.target.value) || 1)
                                      )
                                    }
                                    className="w-12 text-center border-t border-b border-gray-300"
                                  />
                                  <button
                                    onClick={e => {
                                      e.stopPropagation()
                                      handleQuantityChange(
                                        item.id,
                                        (furnitureObj.quantity || 1) + 1
                                      )
                                    }}
                                    className="bg-gray-300 text-gray-700 px-2 py-1 rounded-r"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center gap-4 mt-6 sticky bottom-0 bg-white pt-4 w-full -mt-12">
              {isEditing && (
                <button
                  type="button"
                  onClick={() => onDelete(preset.id)}
                  className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Delete Preset
                </button>
              )}
              <div className="flex gap-4 ml-auto">
                <button
                  onClick={handleClose}
                  className="border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={onSubmit}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                >
                  {isEditing ? "Save Changes" : "Create"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PresetModal
