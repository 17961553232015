// src/pages/SettingsPage.js

import React, { useState, useContext, useEffect } from "react"
import {
  UserIcon,
  LockIcon,
  CreditCardIcon,
  ShieldIcon,
  LogOutIcon,
  AlertTriangleIcon,
  XIcon,
  MenuIcon,
  MailIcon,
  BarChart2,
  User,
} from 'lucide-react'
import { AuthContext } from '../../components/AuthContext.js'
import Footer from "../../components/Footer.js";

export default function SettingsPage() {
  const { user, setUser, checkAuth, logout } = useContext(AuthContext)
  const [email, setEmail] = useState(user?.email || "")
  const [isLoading, setIsLoading] = useState(false)
  const [contactMessage, setContactMessage] = useState("")

  const API_BASE_URL = 
      window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;


  // Handler to submit contact form
  const handleContactSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: user.email, message: contactMessage }),
      })
      if (response.ok) {
        alert("Message sent successfully.")
        setContactMessage("")
      } else {
        const errorData = await response.json()
        alert(`Error sending message: ${errorData.error}`)
      }
    } catch (error) {
      console.error('Error sending message:', error)
      alert("An error occurred while sending your message.")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="flex justify-center w-full bg-[#eeeeee] min-h-screen py-10">
        <div className="w-full max-w-2xl space-y-6">


          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <form onSubmit={handleContactSubmit} className="max-w-2xl">
              <div className="space-y-4">
                <div>
                  <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    id="contactEmail"
                    value={user?.email || ""}
                    readOnly
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1 cursor-not-allowed"
                  />
                </div>
                <div>
                  <label htmlFor="contactMessage" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea
                    id="contactMessage"
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                    required
                    rows="6"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  disabled={isLoading || contactMessage.length < 10}
                  className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                >
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>

      </div>
    </div>
    <Footer top_color={'#eeeeee'}/>
    </>
  )
}
