import React from 'react'
import Squigglyline from "./Squigglyline.js"

const LoadingAnimation = ({top_color}) => {
  return (
      <footer className="flex flex-col items-center justify-center relative w-screen bg-purple-600">
        {/* The line */}
        <div
          className="absolute top-0 left-0 w-full bg-black"
          style={{ height: "2.4px" }}
        ></div>

        <Squigglyline top_color={top_color}/>

        {/* Footer */}
        <div className="text-white w-full mt-20 py-4 px-6 text-center">
          <div className="bg-black text-white w-full rounded-xl px-6 text-center p-4">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between">
              <div className="mb-6 md:mb-0">
                <h1 className="text-xl font-bold mb-2">RoomCustomiser</h1>
                <p className="text-sm">Making every space feels like home.</p>
              </div>
              <div className="flex flex-col space-y-2 mb-6 md:mb-0">
                <a href="/sayhi" className="text-sm hover:underline">
                  Contact Us
                </a>
                <a href="/privacy" className="text-sm hover:underline">
                  Privacy Policy
                </a>
                <a href="/termsofservice" className="text-sm hover:underline">
                  Terms of Service
                </a>
              </div>
              <div className="flex flex-col space-y-2">
                <p className="text-sm">
                  © 2025 RoomCustomiser. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default LoadingAnimation;