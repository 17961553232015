import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="min-h-screen w-full h-full inset-0 bg-[#f9f6f3] flex items-center justify-center">
      <div className="text-center">
        <div className="inline-block relative w-16 h-16">
          {[0, 1, 2].map((i) => (
            <div
              key={i}
              className="absolute w-full h-full border-4 border-blue-500 rounded-full"
              style={{
                animation: `ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite ${i * 0.5}s`,
                opacity: 0,
              }}
            />
          ))}
        </div>
        <p className="mt-4 text-lg text-gray-600">Loading...</p>
      </div>
      <style jsx>{`
        @keyframes ripple {
          0% {
            transform: scale(0);
            opacity: 1;
          }
          100% {
            transform: scale(1);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LoadingScreen;