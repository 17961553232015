import React, { useState } from "react"

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="border-b border-gray-300 py-4">
      <button
        className="w-full text-left focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-800">{question}</span>
          <span>{isOpen ? "-" : "+"}</span>
        </div>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  )
}

const FAQSection = () => {
  const faqs = [
    {
      question: "What is Room Customiser and how can it transform my interior design?",
      answer:
        "Room Customiser is an innovative platform that offers Australia’s handpicked interior design presets and curated furniture lists. Our expert selections make it easy to create stylish, modern spaces whether you're a renter or a homeowner."
    },
    {
      question: "How does Room Customiser curate interior design presets for Australian homes?",
      answer:
        "We carefully selects the best interior styles and pairs them with furniture available in Australia. We focus on trends, functionality, and affordability to ensure each design is both inspirational and practical."
    },
    {
      question: "Are the curated furniture items available for purchase in Australia?",
      answer:
        "Yes, every furniture item featured on Room Customiser is available to order within Australia."
    },
    {
      question: "Does Room Customiser cater to both renters and homeowners?",
      answer:
        "Absolutely. Our design presets transform your space without any permanent modifications—no repainting, drilling, or structural changes required. This means renters, who often face restrictions on altering their walls, can enjoy a stylish, updated look, while homeowners benefit from our innovative, hassle-free design inspirations. Everyone is welcome to enjoy our curated interiors and furniture selections."
    },
    {
      question: "How often are the interior design presets and furniture lists updated?",
      answer:
        "We update our curated interior designs and furniture selections on a weekly basis. This ensures you always have access to the latest trends, seasonal updates, and timeless styles in interior design."
    },
    {
      question: "Do I need to sign up to access the interior designs?",
      answer:
        "Our homepage features a preview of select, handpicked interior design presets. To unlock the full collection—including every design preset and its detailed, curated furniture list—you need to join Room Customiser."
    },
    {
      question: "Can I order individual furniture pieces from a Room Customiser preset?",
      answer:
        "Yes, each preset includes a detailed list of furniture items with ordering information. This allows you to purchase individual pieces or the entire set, giving you the flexibility to customize your space according to your needs."
    },
    {
      question: "Do I need any prior interior design experience to use Room Customiser?",
      answer:
        "Not at all. Our platform is user-friendly and designed for everyone—from interior design novices to seasoned decorators. We provide clear guidance and inspiration so you can confidently create a stylish space."
    }
  ]

  return (
    <section className="pt-12 px-4 w-full mt-12">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Frequently Asked Questions about Room Customiser
        </h2>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </section>
  )
}

export default FAQSection
