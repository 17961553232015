import React, { useState, useContext, useEffect } from "react"
import { AuthContext } from "../../components/AuthContext.js"
import Footer from "../../components/Footer.js"

export default function SettingsPage() {
  const { user, setUser, checkAuth, logout } = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState("profile")
  const [fullName, setFullName] = useState(user?.full_name || "")
  const [email, setEmail] = useState(user?.email || "")
  const [securityCode, setSecurityCode] = useState("")
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  })
  const [passwordError, setPasswordError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const API_BASE_URL =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_LOCAL_API
      : process.env.REACT_APP_PUBLIC_API

  useEffect(() => {
    setFullName(user?.full_name || "")
    setEmail(user?.email || "")
  }, [user])

  const handleUpdatePassword = async e => {
    e.preventDefault()
    setIsLoading(true)

    const { currentPassword, newPassword, confirmPassword } = passwordFields
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match")
      setIsLoading(false)
      return
    }

    setPasswordError("") // Clear error if passwords match

    try {
      const response = await fetch(`${API_BASE_URL}/api/user/password`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ currentPassword, newPassword })
      })
      if (response.ok) {
        alert("Password updated successfully")
        setPasswordFields({ currentPassword: "", newPassword: "", confirmPassword: "" })
      } else {
        const { error } = await response.json()
        alert(`Error updating password: ${error}`)
      }
    } catch (error) {
      console.error("Error updating password:", error)
      alert("An error occurred while updating password.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateFullName = async e => {
    e.preventDefault()
    if (fullName === user?.full_name) return

    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/fullname`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ full_name: fullName })
      })
      if (response.ok) {
        const { user: updatedUser } = await response.json()
        await checkAuth()
        window.location.reload()
      } else {
        const { error } = await response.json()
        alert(`Error updating full name: ${error}`)
      }
    } catch (error) {
      console.error("Error updating full name:", error)
      alert("An error occurred while updating full name.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateEmail = async e => {
    e.preventDefault()
    if (email === user?.email) return

    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/email`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ new_email: email })
      })
      if (response.ok) {
        const user = await checkAuth()
        setUser(user)
      } else {
        const { error } = await response.json()
        alert(`Error updating email: ${error}`)
      }
    } catch (error) {
      console.error("Error updating email:", error)
      alert("An error occurred while updating email.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerifyEmail = async e => {
    e.preventDefault()
    if (!securityCode) return

    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/verify-new-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email: user.email, new_email: user.new_email, code: securityCode })
      })
      if (response.ok) {
        await checkAuth()
        setSecurityCode("")
        window.location.reload()
        alert("Email verified successfully.")
      } else {
        const { error } = await response.json()
        alert(`Error verifying email: ${error}`)
      }
    } catch (error) {
      console.error("Error verifying email:", error)
      alert("An error occurred while verifying email.")
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <div className="flex justify-center w-full bg-[#eeeeee] min-h-screen py-10">
        <div className="w-full max-w-2xl space-y-6">
          <div className="bg-white shadow rounded-lg p-6">
            <form onSubmit={handleUpdateEmail} className="space-y-4">
              <h3 className="text-xl font-semibold mb-2 text-center">Update Email</h3>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                required
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || email === user?.email}
                className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
              >
                {isLoading ? "Updating..." : "Update Email"}
              </button>
            </form>

            {user?.new_email?.length > 0 && (
              <div className="mt-6">
                <p className="text-sm text-gray-700">
                  A verification code has been sent to <strong>{user.new_email}</strong>.
                  Please enter it below to confirm your new email.
                </p>
                <form onSubmit={handleVerifyEmail} className="mt-4 space-y-4">
                  <label htmlFor="securityCode" className="block text-sm font-medium text-gray-700">
                    Security Code
                  </label>
                  <input
                    type="text"
                    id="securityCode"
                    value={securityCode}
                    onChange={e => setSecurityCode(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 border border-gray-300"
                    required
                    disabled={isLoading}
                  />
                  <button
                    type="submit"
                    disabled={isLoading || !securityCode}
                    className="w-full bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50"
                  >
                    {isLoading ? "Verifying..." : "Confirm Email"}
                  </button>
                </form>
              </div>
            )}
          </div>

              <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-center">Update Password</h2>
                <form onSubmit={handleUpdatePassword} className="space-y-4">
                  {["currentPassword", "newPassword", "confirmPassword"].map(field => (
                    <div key={field}>
                      <label htmlFor={field} className="block text-sm font-medium text-gray-700">
                        {field === "currentPassword" ? "Current Password" : field === "newPassword" ? "New Password" : "Confirm New Password"}
                      </label>
                      <input
                        type="password"
                        id={field}
                        value={passwordFields[field]}
                        onChange={e => {
                          setPasswordFields({ ...passwordFields, [field]: e.target.value })
                          if (field === "confirmPassword" && e.target.value !== passwordFields.newPassword) {
                            setPasswordError("Passwords do not match")
                          } else if (field === "confirmPassword") {
                            setPasswordError("")
                          }
                        }}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                        disabled={isLoading}
                      />
                    </div>
                  ))}
                  {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
                  <button
                    type="submit"
                    disabled={
                      isLoading ||
                      !passwordFields.currentPassword ||
                      !passwordFields.newPassword ||
                      !passwordFields.confirmPassword ||
                      passwordError
                    }
                    className="w-full bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 disabled:opacity-50"
                  >
                    {isLoading ? "Updating..." : "Update Password"}
                  </button>
                </form>
              </div>
        </div>
      </div>
      <Footer top_color="#eeeeee" />
    </>
  )
}
