import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AuthContext } from "../../components/AuthContext.js";

const SignupPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyingLoading, setIsVerifyingLoading] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [canResend, setCanResend] = useState(true)
  const [resendTimer, setResendTimer] = useState(0)
  const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001'

  const { checkAuthAfterLogin } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    let timerInterval
    if (resendTimer > 0) {
      timerInterval = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            clearInterval(timerInterval)
            setCanResend(true)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(timerInterval)
  }, [resendTimer])

  const handleSignup = async e => {
    e.preventDefault()
    setError('')
    setSuccess('')
    if (password !== confirmPassword) {
      setError('Passwords do not match.')
      return
    }
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
      const data = await response.json()
      if (response.ok) {
        await checkAuthAfterLogin()
        window.location.replace('/confirm')
        // navigate('/confirm')
      }
      else {
        setError(data.error || 'An error occurred during signup.')
      }
    }
    catch (err) {
      console.error('Signup error:', err)
      setError('Failed to connect to the server. Please try again later.')
    }
    finally {
      setIsLoading(false)
    }
  }

  const Step = ({ number, title, active = false }) => {
    return (
      <div className="flex flex-col items-center">
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold ${
            active ? "bg-purple-500 text-white" : "bg-gray-300 text-gray-600"
          }`}
        >
          {number}
        </div>
        <div className={`mt-2 text-xs font-medium text-center ${active ? "text-purple-600" : "text-gray-500"}`}>{title}</div>
      </div>
    )
  }

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <header className="absolute top-0 flex justify-between w-full p-6 z-50">
        <a href="/" className="text-red-600 hover:underline">
          &larr; Back to Homepage
        </a>
        <div className="flex space-x-4 hidden lg:block">
          <button
            className="less_cool_button_with_shadow py-2 px-6 rounded-full relative"
            onClick={() => window.location.href = '/login'}
          >
            Already a Member? Log In
          </button>
        </div>
      </header>
      <main className="grid h-full bg-base-300 lg:grid-cols-12">
        <div className="col-span-5 h-screen flex flex-col w-full overflow-hidden border-r border-base-content/10 brightness-10 max-lg:hidden items-center justify-center p-4 bg-purple-100">
          <img
            src="https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1738285905259-1737939536369-01_Electic%20Chic.png"
            alt="Join now"
            className="w-full object-cover rounded"
          />
          <p className="mt-4 text-lg text-gray-700">
            Join to unlock access to all interior designs
          </p>
        </div>
        <div className="col-span-7 flex w-full flex-col items-center justify-center px-8 py-16 items-center justify-center p-4">
              <h1 className="text-3xl font-bold text-gray-900 leading-tight text-center">
                WOOOOOO 👋
              </h1>
              <p className="text-md md:text-xl mb-8 max-w-3xl text-center">
                We're so excited to show you the amazing designs we've made for you.
              </p>

              <div className="w-full bg-gray-100 p-4 rounded-t-lg shadow-md px-2 md:px-12 max-w-4xl">
                <div className="flex justify-between items-center">
                  <Step number={1} title="Enter Details" active />
                  <Step number={2} title="Confirm Email" />
                  <Step number={3} title="Membership Fee" />
                </div>
              </div>
              <form
                onSubmit={handleSignup}
                className="w-full bg-white p-6 rounded-b-lg shadow-md space-y-4 max-w-4xl"
              >
                <div className="flex flex-col text-left">
                  <label htmlFor="email" className="mb-1 text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                    placeholder="you@example.com"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="password" className="mb-1 text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                    placeholder="••••••••"
                  />
                </div>
                <div className="flex flex-col text-left">
                  <label htmlFor="confirmPassword" className="mb-1 text-gray-700">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    required
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                    placeholder="••••••••"
                  />
                </div>
                {error && (
                  <div className="text-red-500 text-sm">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="text-green-500 text-sm">
                    {success}
                  </div>
                )}
                <button
                  type="submit"
                  className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Next Step'}
                </button>
              </form>
          <p className="text-sm text-gray-600 mt-4 text-center">
            By signing up, you agree to our{' '}
            <a href="/termsofservice" className="text-red-600 hover:underline">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy" className="text-red-600 hover:underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </main>
    </div>
  )
}

export default SignupPage
