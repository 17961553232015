import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import motion from framer-motion

const API_BASE_URL =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

const designOptions = [
  { id: 1, name: 'Contemporary Chic', image: '/assets/LT01.png' },
  { id: 2, name: 'Rustic Warmth', image: '/assets/LT02.png' },
  { id: 3, name: 'Minimalist Zen', image: '/assets/VS05.png' },
  { id: 4, name: 'Modern Elegance', image: '/assets/VS02.png' },
];

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true); // Set loading to true when the request is initiated

    try {
      const response = await fetch(`${API_BASE_URL}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          resetToken: resetToken,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || 'Password reset successful.');
        setTimeout(() => {
          navigate('/login'); // Redirect to login after successful reset
        }, 2000);
      } else {
        setError(data.error || 'Error resetting password.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsLoading(false); // Set loading to false when the request completes
    }
  };

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      {/* Header */}
      <header className="absolute top-0 flex justify-between w-full p-6 max-w-7xl z-50">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center text-center px-4 relative z-10 w-full max-w-md">
        <h1 className="text-5xl font-bold text-gray-900 leading-tight mb-4">
          Reset Your Password
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          Enter your new password below to reset it.
        </p>

        {/* Reset Password Form */}
        <form
          onSubmit={handleResetPassword}
          className="w-full bg-white p-6 rounded-lg shadow-md space-y-4"
        >
          {/* New Password Field */}
          <div className="flex flex-col text-left">
            <label htmlFor="newPassword" className="mb-1 text-gray-700">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="Enter your new password"
            />
          </div>

          {/* Error Message */}
          {error && (
            <div className="text-red-500 text-sm">
              {error}
            </div>
          )}

          {/* Success Message */}
          {message && (
            <div className="text-green-500 text-sm">
              {message}
            </div>
          )}

          <button
            type="submit"
            className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading} // Disable the button when isLoading is true
          >
            {isLoading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>

        {/* Back to Login */}
        <button
          type="button"
          onClick={() => navigate('/login')}
          className="w-full mt-4 text-center text-purple-600 hover:underline"
        >
          Back to Login
        </button>
      </main>

      {/* Design Options - Optional */}
      <div className="w-[80vw] hidden md:flex z-0">
        {designOptions.map((item, index) => (
          <motion.div
            key={item.id}
            className="absolute w-60 h-auto"
            style={{
              top: `${10 + index * 18}%`,
              left: `${index % 2 === 0 ? '5%' : '80%'}`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.9,
              zIndex: 0,
            }}
            animate={{ y: [0, -15, 0] }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              delay: index * 0.5,
            }}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-full object-cover rounded-md"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ResetPassword;
