// src/contexts/AuthContext.js
import React, { useEffect, createContext, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import LoadingScreen from './LoadingScreen'
import PayNow from '../pages/Other/PayNow'
import ConfirmEmail from '../pages/Other/ConfirmEmail'

const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cachedUser = localStorage.getItem('user')
    const parsedUser = cachedUser ? JSON.parse(cachedUser) : {}
    setUser(parsedUser)
    setLoading(false)
    // deleteAccount()
  }, [])

  const logout = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/logout`, {
        method: 'POST',
        credentials: 'include'
      })
      if (response.ok) {
        setUser(JSON.stringify({}))
        await localStorage.removeItem('user')
        navigate('/')
      }
      else {
        console.error('Failed to logout.')
        setUser(JSON.stringify({}))
        await localStorage.removeItem('user')
        navigate('/')
      }
    }
    catch (error) {
      console.error('Logout error:', error)
      setUser(JSON.stringify({}))
      localStorage.removeItem('user')
      navigate('/')
    }
    finally {
      setLoading(false)
    }
  }

  const checkAuth = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/auth/me`, {
        method: 'GET',
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('user', JSON.stringify(data.user))
        return data.user
      }
      else if (response.status === 401) {
        await logout()
      }
      else {
        console.error('Failed to authenticate.')
        setUser({})
        localStorage.removeItem('user')
      }
    }
    catch (error) {
      console.error('Error checking authentication:', error)
      setUser(null)
      localStorage.removeItem('user')
    }
    finally {
      setLoading(false)
    }
  }

  const checkAuthAfterLogin = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/me`, {
        method: 'GET',
        credentials: 'include'
      })
      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('user', JSON.stringify(data.user))
        return data.user
      }
      else {
        console.error('Failed to authenticate.')
        setUser(JSON.stringify({}))
        localStorage.removeItem('user')
      }
    }
    catch (error) {
      console.error('Error checking authentication:', error)
      setUser(JSON.stringify({}))
      localStorage.removeItem('user')
    }
    finally {
      setLoading(false)
    }
  }

  const logoutAllDevices = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/logoutAll`, {
        method: 'POST',
        credentials: 'include'
      })
      if (response.ok) {
        setUser(JSON.stringify({}))
        localStorage.removeItem('user')
        navigate('/')
        alert('Logged out from all devices successfully.')
      }
      else {
        console.error('Failed to logout from all devices.')
      }
    }
    catch (error) {
      console.error('Error logging out from all devices:', error)
    }
  }

  const deleteAccount = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/user`, {
        method: 'DELETE',
        credentials: 'include'
      })
      if (response.ok) {
        setUser(JSON.stringify({}))
        localStorage.removeItem('user')
        navigate('/')
      }
      else {
        console.error('Failed to delete account.')
      }
    }
    catch (error) {
      console.error('Error deleting account:', error)
    }
    finally {
      setLoading(false)
    }
  }

  if (loading) return <LoadingScreen/>

  if (user.email && !user.new_email && !user.is_verified && !window.location.href.includes('/confirm') && !window.location.href.includes('/contact')) return <Navigate to="/confirm" replace />

  if (user.id && user.is_verified && !user.has_paid_membership && (!window.location.href.includes('/membership')) ) return <Navigate to="/membership" replace />

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        logout,
        logoutAllDevices,
        deleteAccount,
        checkAuth,
        checkAuthAfterLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
