import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { AuthContext } from "../../components/AuthContext.js";

const ConfirmEmail = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyingLoading, setIsVerifyingLoading] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [canResend, setCanResend] = useState(true)
  const [resendTimer, setResendTimer] = useState(0)
  const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001'

  const { user, logout, checkAuthAfterLogin } = useContext(AuthContext);

  useEffect(() => {
    if (user?.is_verified == true) window.location.replace('/')
  }, [user]);

  useEffect(() => {
    let timerInterval
    if (resendTimer > 0) {
      timerInterval = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            clearInterval(timerInterval)
            setCanResend(true)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(timerInterval)
  }, [resendTimer])

  const handleVerification = async e => {
    e.preventDefault()
    setError('')
    setSuccess('')
    if (!verificationCode) {
      setError('Please enter the verification code.')
      return
    }
    setIsVerifyingLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email,
          code: verificationCode
        }),
        credentials: 'include'
      })
      const data = await response.json()
      if (response.ok) {
        await checkAuthAfterLogin()
        window.location.replace('/')
      }
      else {
        setError(data.error || 'Verification failed. Please check your code and try again.')
      }
    }
    catch (err) {
      console.error('Verification error:', err)
      setError('Failed to connect to the server. Please try again later.')
    }
    finally {
      setIsVerifyingLoading(false)
    }
  }

  const handleResendCode = async () => {
    setError('')
    setSuccess('')
    setIsResending(true)
    try {
      const response = await fetch(`${API_BASE_URL}/resend-verification-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email
        }),
        credentials: 'include'
      })
      const data = await response.json()
      if (response.ok) {
        setSuccess(data.message || 'A new verification code has been sent to your email.')
        setCanResend(false)
        setResendTimer(200)
      }
      else {
        setError(data.error || 'Failed to resend verification code.')
        if (data.error && data.error.includes('wait')) {
          const match = data.error.match(/wait (\d+) more minute\(s\)/)
          if (match && match[1]) {
            setCanResend(false)
            setResendTimer(match[1] * 60)
          }
        }
      }
    }
    catch (err) {
      console.error('Resend verification code error:', err)
      setError('Failed to connect to the server. Please try again later.')
    }
    finally {
      setIsResending(false)
    }
  }

  const Step = ({ number, title, active = false }) => {
    return (
      <div className="flex flex-col items-center">
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold ${
            active ? "bg-purple-500 text-white" : "bg-gray-300 text-gray-600"
          }`}
        >
          {number}
        </div>
        <div className={`mt-2 text-xs font-medium text-center ${active ? "text-purple-600" : "text-gray-500"}`}>{title}</div>
      </div>
    )
  }

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <header className="absolute top-0 flex justify-between w-full p-6 z-50">
        <p href="/" className="text-red-600 hover:underline cursor-pointer" onClick={logout}>
          &larr; Back to Homepage
        </p>
      </header>
      <main className="grid h-screen bg-base-300 lg:grid-cols-12">
        <div className="col-span-5 h-full flex flex-col w-full overflow-hidden border-r border-base-content/10 brightness-10 max-lg:hidden items-center justify-center p-4 bg-purple-100">
          <img
            src="https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1738285905259-1737939536369-01_Electic%20Chic.png"
            alt="Join now"
            className="w-full object-cover rounded"
          />
          <p className="mt-4 text-lg text-gray-700">
            Join to unlock access to all interior designs
          </p>
        </div>
        <div className="col-span-7 flex w-full flex-col items-center justify-center px-8 py-16 items-center justify-center p-4">
              <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-8">
                Verify Your Email
              </h1>

              <div className="w-full bg-gray-100 p-4 rounded-t-lg shadow-md px-2 md:px-12 max-w-4xl">
                <div className="flex justify-between items-center">
                  <Step number={1} title="Enter Details" active />
                  <Step number={2} title="Confirm Email" active/>
                  <Step number={3} title="Membership Fee" />
                </div>
              </div>
              <form
                onSubmit={handleVerification}
                className="w-full bg-white p-6 rounded-lg shadow-md space-y-4 max-w-4xl"
              >
                <input
                  type="email"
                  id="email"
                  required
                  value={email}
                  readOnly
                  className="hidden"
                />
                <div className="flex flex-col text-left">
                  <label htmlFor="verificationCode" className="mb-1 text-gray-700">
                    Enter the confirmation code sent to <span className="text-gray-700 italic" >{user.email}</span>
                  </label>
                  <input
                    type="text"
                    id="verificationCode"
                    required
                    value={verificationCode}
                    onChange={e => setVerificationCode(e.target.value)}
                    className="border border-gray-300 p-2 mt-4 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                    placeholder="Enter the code here"
                  />
                </div>
                {error && (
                  <div className="text-red-500 text-sm">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="text-green-500 text-sm">
                    {success}
                  </div>
                )}
                <button
                  type="submit"
                  className={`w-full cool_button py-2 px-4 rounded-full ${isVerifyingLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isVerifyingLoading}
                >
                  {isVerifyingLoading ? 'Verifying...' : 'Verify Email'}
                </button>
              </form>
              <div className="mt-4">
                <button
                  onClick={handleResendCode}
                  className={`less_cool_button py-2 px-4 rounded-full ${!canResend ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!canResend || isResending}
                >
                  {isResending ? 'Resending...' : canResend ? 'Resend Code' : `Resend in ${Math.floor(resendTimer / 60)}m ${resendTimer % 60}s`}
                </button>
              </div>
          <p className="text-sm text-gray-600 mt-4">
            By signing up, you agree to our{' '}
            <a href="/termsofservice" className="text-red-600 hover:underline">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy" className="text-red-600 hover:underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </main>
    </div>
  )
}

export default ConfirmEmail
