import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Check, CheckCheck } from "lucide-react";

import { AuthContext } from "./components/AuthContext.js";
import LoadingAnimation from "./components/LoadingAnimation.js";
import Footer from "./components/Footer.js";
import FAQ from "./components/FAQ.js";

import Squigglyline from "./components/Squigglyline.js";

const baseDomain =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_PUBLIC_API;

const designOptions = [
  { id: 1, name: "Contemporary Chic", image: "https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1739416375817-LT01png" },
  { id: 2, name: "Rustic Warmth", image: "https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1739416359606-CS01png" },
  { id: 3, name: "Minimalist Zen", image: "https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1739416306994-CH01png" },
  { id: 4, name: "Minimalist Zen", image: "https://room-customiser.s3.us-west-1.amazonaws.com/uploads/1739416398184-VS04png" },
]

const arrow_right = () => {
  return (
    <div className="half_cool_border py-2 px-6 rounded-full relative border-2 border-black mx-12">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-move-right"
      >
        <path d="M18 8L22 12L18 16"></path>
        <path d="M2 12H22"></path>
      </svg>
    </div>
  );
};

const arrow_down = () => {
  return (
    <div className="half_cool_border py-2 px-6 rounded-full relative border-2 border-black my-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-move-down"
      >
        <path d="M8 18L12 22L16 18"></path>
        <path d="M12 2V22"></path>
      </svg>
    </div>
  );
};

const fetchPresets = async () => {
  const res = await fetch(baseDomain + "/api/frontpagepresets");
  if (!res.ok) {
    throw new Error("Failed to fetch presets");
  }
  const presets = await res.json();
  return presets;
};

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [presets, setPresets] = useState([])
  const [examplePreset, setExamplePreset] = useState({})
  const [total, setTotal] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchPresets();
        if (data) {
          setPresets(data?.presets || []);
          setTotal(data.total)
          setExamplePreset(data.examplePreset)
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (user?.id) navigate("/presets");
  }, [user])

  const buttonClasses = category => {
    const active =
      (category === null && selectedCategory === null) ||
      category === selectedCategory
    return `text-xl font-semibold border border-2 rounded-full px-2 py-2 less_cool_button_with_shadow_no_hover ${
      active ? 'bg-purple-300 cursor-default' : 'border-black cursor-pointer'
    }`
  }

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <main className="flex min-h-[500px] flex-col items-center justify-center text-center px-4 relative z-1">
        <h1 className="text-3xl md:text-4xl max-w-3xl font-bold text-gray-900 leading-tight z-50">
          We've handpicked Australia's best Interiors just for you!
        </h1>
        <h1 className="text-2xl font-bold text-purple-600 leading-tight z-50 pt-4">
          Perfect even for renters
        </h1>
        <p className="text-lg lg:text-lg text-gray-600 mt-8 max-w-xl">
          We understand the pain of going through hundreds of stores and
          combining furniture, so we made it fun and easy.
        </p>

        <div className="flex space-x-4 mt-4">
          <button
            className="less_cool_button_with_shadow py-2 px-4 rounded-full relative"
            onClick={() => (window.location.href = "/join")}
          >
            Unlock and explore all designs
          </button>
        </div>

        <div className="w-[80vw] hidden xl:flex z-0">
          {designOptions.map((item, index) => (
            <motion.div
              key={item.id}
              className="absolute w-40 h-auto"
              style={{
                top: `${10 + index * 19}%`,
                left: `${index % 2 === 0 ? "0%" : "86%"}`,
                transform: "translate(-50%, -50%)",
                opacity: 0.9,
                zIndex: 0,
              }}
              animate={{ y: [0, -15, 0] }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                delay: index * 0.5,
              }}
            >
            <img
              src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/160x160/${item.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
              alt={'furniture background sample '+(index+1)}
              className="w-40 h-auto object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
            />

            </motion.div>
          ))}
        </div>
      </main>

      <div
        className="flex flex-col items-center justify-center relative w-screen pb-12"
        style={{ background: "#eeeeee" }}
      >
        {/* The line */}
        <div
          className="absolute top-0 left-0 w-full bg-black"
          style={{ height: "2.4px" }}
        ></div>

        
          <Squigglyline top_color={"#f8f6f3"}/>




        <h2 className="text-2xl font-bold text-center text-gray-900 mt-20 pb-4">
          Just choose an interior style to see its curated furniture list:
        </h2>

        <div className="flex flex-row flex-wrap justify-center pb-12 space-x-4 space-y-4">
          <button
            onClick={() => setSelectedCategory(null)}
            className={buttonClasses(null)+' mt-4'}
            disabled={selectedCategory === null}
          >
            All Categories
          </button>
          <button
            onClick={() => setSelectedCategory('Living Room')}
            className={buttonClasses('Living Room')}
            disabled={selectedCategory === 'Living Room'}
          >
            Living Room
          </button>
          <button
            onClick={() => setSelectedCategory('Dining Room')}
            className={buttonClasses('Dining Room')}
            disabled={selectedCategory === 'Dining Room'}
          >
            Dining Room
          </button>
          <button
            onClick={() => setSelectedCategory('Bedroom')}
            className={buttonClasses('Bedroom')}
            disabled={selectedCategory === 'Bedroom'}
          >
            Bedroom
          </button>
          <button
            onClick={() => setSelectedCategory('Working Area')}
            className={buttonClasses('Working Area')}
            disabled={selectedCategory === 'Working Area'}
          >
            Working Area
          </button>
        </div>

        <div className="container mx-auto px-4 pb-2">
          {loading && <LoadingAnimation />}
          <motion.div
            className="grid grid-cols-1 lg:grid-cols-2 gap-8"
            // variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {presets.filter(x => !selectedCategory ? true : x.category == selectedCategory).map((preset, index) => (
              <motion.div
                key={preset.name}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden p-2 bg-white cursor-pointer"
                // variants={itemVariants}
                onClick={() => navigate("/preset/preview/" + preset.id)}
              >
                <div className="relative overflow-hidden w-full">
                  <img
                    src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/320x320/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                    srcSet={`
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/480x480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 320w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/768x768/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 480w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1024x1024/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 768w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1024w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${preset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1480w
                    `}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt={preset.name}
                    className="w-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                  />
                </div>
                <div className="pt-4 bg-white">
                  <h3 className="text-xl text-center font-semibold text-gray-800">
                    {preset.name}
                  </h3>
                  <div className="flex justify-between items-center text-sm text-gray-600 mt-2">
                    <div className="inline flex space-x-1" >
                    <p className="border border-2 rounded-full border-gray-400 text-gray-500 p-1" >{preset.category}</p>
                    <p className="border border-2 rounded-full border-gray-400 text-gray-500 p-1" >{preset.furniture_number} items </p>
                    </div>
                    <p className="text-right border border-2 rounded-full border-gray-400 text-gray-500 p-1">
                      <strong>Total Price:</strong> ${preset.total_cost}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          <div className="flex space-x-4 justify-center mt-12">
            <p>And many more...</p>
          </div>
          <div className="flex space-x-4 justify-center mt-2">
          </div>
        </div>

  <section className="mt-8 rounded-lg text-center w-full relative flex flex-col items-center justify-center px-2">
    <div className="cool_border px-4 md:px-16 py-12 rounded-lg shadow-md max-w-7xl w-full border-2 border-black relative bg-purple-300">
      <h2 className="text-3xl text-center text-gray-900 underline">
        Wait, We've Got More!
      </h2>

      <h2 className="text-3xl lg:text-5xl font-bold text-center text-gray-900 mt-4">
        We have {total} Awesome Interiors to <span className="underline">save you money</span> by eliminating costly design mistakes!
      </h2>

      <div className="flex flex-wrap justify-center gap-8 w-full mt-12">
        {/* First Pricing Card */}
        <div className="max-w-sm mt-8">
          <div className="less_cool_button_with_shadow_no_hover rounded-lg border bg-white text-gray-900 shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-3xl font-semibold leading-none tracking-tight">Join to view all our Interior Presets</h3>
              <p className="text-sm text-gray-500">Including any update of furniture and future features</p>
            </div>
            <div className="p-6 pt-0 grid gap-4">
              <div className="flex items-baseline justify-center gap-1">
                <span className="text-4xl font-bold">$9.99</span>
                <span className="text-gray-500">AUD</span>
              </div>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all interior presets</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all future designs</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Time-saving tools and designs</span>
                </li>
              </ul>
            </div>
            <div className="flex items-center p-6 pt-0">
              <button className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative" onClick={() => (window.location.href = "/join")}>
                Join Now!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    </section>


        <section className="py-8 pt-12 pb-12 mt-8 rounded-lg text-center w-full relative flex flex-col items-center justify-center px-4">

<div className="w-full flex flex-col mb-4 mt-4 max-w-7xl space-y-8">
  
  {/* Row for 1 and 2 on Desktop */}
  <div className="w-full flex flex-col md:flex-row md:space-x-4 space-y-8 md:space-y-0">
    <div className="w-full md:w-1/2 bg-white cool_border rounded-lg border-2 border-black relative flex flex-col justify-center items-center py-8 px-2">
      <div className="absolute top-2 left-2 half_cool_border py-2 px-6 rounded-full border-2 border-black mx-2">
        <p className="text-xl"><CheckCheck size="24" /></p>
      </div>
      <img
        src="/assets/DESIGN OPTION 3A.jpg"
        alt="Market Hunt Icon"
        className="w-auto h-48 mt-12 mb-4"
      />
      <h3 className="text-center text-3xl font-bold text-gray-900 mb-2 pt-4">
        Forget the endless market hunt
      </h3>
      <p className="text-xl lg:text-lg text-center max-w-lg pt-4 lg:pt-8 pb-8 md:pb-0">
        Stop scouring countless stores and piecing together mismatched items. We’ve curated the perfect combinations so you never have to. Simply browse our curated interior presets, and choose the ones that you like the most.
      </p>
    </div>

    <div className="w-full md:w-1/2 bg-white cool_border rounded-lg border-2 border-black relative flex flex-col justify-center items-center py-8 px-2">
      <div className="absolute top-2 left-2 half_cool_border py-2 px-6 rounded-full border-2 border-black mx-2">
        <p className="text-xl"><CheckCheck size="24" /></p>
      </div>
      <img
        src="/assets/list.png"
        alt="Room Preview Icon"
        className="w-auto h-48 mt-12 mb-4"
      />
      <h3 className="text-center text-3xl font-bold text-gray-900 mb-2 pt-4">
        See a list of all the furniture we used
      </h3>
      <p className="text-xl lg:text-lg text-center max-w-lg pt-4 lg:pt-8 pb-8 md:pb-0">
        See the complete lineup of furniture in every design preset we've built. Every item is showcased with measurements, price, link to order it, and more so you know exactly how it fits into your dream interior and where to get it from.
      </p>
    </div>
  </div>

  {!loading && examplePreset &&
  <div className="w-full bg-white cool_border rounded-lg border-2 border-black relative flex flex-col justify-center items-center py-8 px-2 lg:px-12">
            <h2 className="text-4xl lg:text-5xl font-bold text-center b-8  underline bg-purple-300 px-2 py-1 inline rounded-sm ">
              Our designs save YOU money and time
            </h2>

    <h3 className="text-center text-3xl lg:text-4xl font-bold text-gray-900 mb-2 mt-12 lg:mt-4">
      Not sure? Here's an example:
    </h3>

                  <img
                    src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/320x320/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                    srcSet={`
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/480x480/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 320w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/768x768/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 480w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1024x1024/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 768w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1024w,
                      https://dpi84emh0ku5w.cloudfront.net/fit-in/1480x1480/${examplePreset.image?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')} 1480w
                    `}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt={examplePreset.name}
                    className="w-full px-0 lg:px-12 object-cover"
                  />

      <div className="p-4 mt-4 w-full">
        <div className="overflow-x-auto px-2 w-full">
          <h3 className="text-2xl font-semibold mb-2 pt-8">Example Furniture List:</h3>

          {examplePreset.furniture?.slice(0,4).map((item, index) => (
            <div
              key={item.id}
              className={`flex flex-col md:flex-row items-start sm:items-center gap-4 mb-4 p-4 border rounded-lg bg-gray-100 ${
                index === 2
                  ? "filter blur-sm"
                  : index === 3 ? "filter blur-lg" : "" // No blur for the first preset
              }`}
            >
              {/* Quantity and Image Container */}
              <div className="flex items-center space-x-2 w-full md:w-auto">
                {/* Quantity */}
              <div className="inline flex items-center justify-center md:justify-left w-full">
                {item.quantity && (
                  <span className="text-lg font-bold text-gray-800">
                    {item.quantity}x
                  </span>
                )}
                {/* Image */}
                    <img
                      //src={item.image_url}
                      src={`https://dpi84emh0ku5w.cloudfront.net/fit-in/140x140/${item.image_url?.replace('https://room-customiser.s3.us-west-1.amazonaws.com/', '')}`}
                      alt={item.product_name}
                      className="w-24 h-24 object-contain cursor-pointer"
                    />
                </div>
              </div>

              {/* Furniture Details */}
              <div className="w-full flex-1 text-center md:text-left">
                <p className="font-semibold text-lg">{item.product_name}</p>
                <p className="text-gray-600">Supplier: {item.supplier}</p>

                {/* Additional Details */}
                <div className="mt-2 space-y-1">
                  {item.height && (
                    <p className="text-gray-700">Height: {item.height} cm</p>
                  )}
                  {item.length && (
                    <p className="text-gray-700">Length: {item.length} cm</p>
                  )}
                  {item.depth && (
                    <p className="text-gray-700">Depth: {item.depth} cm</p>
                  )}
                  {item.finish_colour && (
                    <p className="text-gray-700">
                      Finish Colour: {item.finish_colour}
                    </p>
                  )}
                </div>
              </div>

              {/* Order Link and Cost */}
              <div className="flex flex-col items-center md:items-start mt-4 sm:mt-0 w-full md:w-auto">
                <a
                  //href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block cursor-pointer"
                  aria-label={`Order ${item.product_name}`}
                >
                  Link To Order Item
                </a>
                {/* Cost */}
                {item.cost && (
                  <div className="w-full text-center">
                  <p className="mt-4 text-gray-700">
                    Item Price: ${Number(item.cost).toFixed(2)}
                  </p>
                  </div>
                )}
              </div>
            </div>
          ))}

           <p className="text-xl font-semibold mb-2 pt-8">And so on...</p>

        </div>
      </div>



  </div>
  }

</div>




        </section>

        <FAQ/>
      </div>

      

      <Footer top_color={'#eeeeee'}/>
    </div>
  );
};

export default LandingPage;