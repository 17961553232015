import React from "react";

function FurnitureModal({
  isOpen,
  onClose,
  furnitureItem,
  setFurnitureItem,
  onSubmit,
  isEditing,
  loading,
  onImageChange,
  onDelete,
}) {
  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFurnitureItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(); // Execute the passed `onSubmit` function
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this furniture item? This action cannot be undone."
    );
    if (confirmDelete) {
      onDelete(furnitureItem.id); // Call the delete handler with the furniture ID
    }
  };


  return (
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div className="bg-white rounded-lg w-11/12 md:w-2/3 h-screen p-6 flex flex-col h-[95vh]">
    {loading ? (
      <div className="text-center py-6">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-lg font-semibold">Loading...</p>
      </div>
    ) : (
      <>
        <h2 className="text-2xl font-bold mb-4">
          {isEditing ? "Edit Furniture" : "Add New Furniture"}
        </h2>
        <form
          onSubmit={(e) => {
            handleSubmit(2);
          }}
          className="flex-1 overflow-y-auto"
        >
          {/* Existing form fields */}
          <div className="mb-4">
            <label className="block text-gray-700">Code *</label>
            <input
              type="text"
              name="code"
              value={furnitureItem.code || ""}
              onChange={handleChange}
              required
              className="w-full border rounded px-3 py-2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Product Name *</label>
            <input
              type="text"
              name="product_name"
              value={furnitureItem.product_name || ""}
              onChange={handleChange}
              required
              className="w-full border rounded px-3 py-2"
            />
          </div>



          {/* Length, Height, Depth, Cost - Two per row */}
          <div className="grid grid-cols-2 gap-4 mb-4">
         
          <div className="">
            <label className="block text-gray-700">Finish/Colour</label>
            <input
              type="text"
              name="finish_colour"
              value={furnitureItem.finish_colour || ""}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2"
            />
          </div>

          <div className="">
            <label className="block text-gray-700">Supplier</label>
            <input
              type="text"
              name="supplier"
              value={furnitureItem.supplier || ""}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2"
            />
          </div>

            <div>
              <label className="block text-gray-700">Length (cm)</label>
              <input
                type="number"
                name="length"
                value={furnitureItem.length || ""}
                onChange={handleChange}
                step="0.01"
                min="0"
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-gray-700">Height (cm)</label>
              <input
                type="number"
                name="height"
                value={furnitureItem.height || ""}
                onChange={handleChange}
                step="0.01"
                min="0"
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-gray-700">Depth (cm)</label>
              <input
                type="number"
                name="depth"
                value={furnitureItem.depth || ""}
                onChange={handleChange}
                step="0.01"
                min="0"
                className="w-full border rounded px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-gray-700">Cost ($)</label>
              <input
                type="number"
                name="cost"
                value={furnitureItem.cost || ""}
                onChange={handleChange}
                step="0.01"
                min="0"
                className="w-full border rounded px-3 py-2"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">URL</label>
            <input
              type="url"
              name="url"
              value={furnitureItem.url || ""}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2"
            />
          </div>

          {/* Image Upload */}
          <div className="mb-4">
            <label className="block text-gray-700">Image Upload</label>
            <input
              type="file"
              accept="image/*"
              onChange={onImageChange}
              className="w-full"
            />
            {furnitureItem.imageUploading && (
              <p className="text-blue-600">Uploading image...</p>
            )}
            {furnitureItem.image_url && (
              <img
                src={furnitureItem.image_url}
                alt={furnitureItem.product_name}
                className="mt-2 h-24 object-contain"
              />
            )}
          </div>
        </form>

        {/* Submit Buttons */}
        <div className="flex justify-between mt-4 sticky bottom-0 bg-white pt-4">
          {/* Left-aligned Delete Button */}
          {isEditing && (
            <button
              type="button"
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
            >
              Delete
            </button>
          )}

          {/* Right-aligned Cancel and Save Buttons */}
          <div className="flex gap-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : isEditing ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </>
    )}
  </div>
</div>

  );
}

export default FurnitureModal;
