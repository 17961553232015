import React, { useState, useContext, useEffect } from "react"
import {
  UserIcon,
  LockIcon,
  CreditCardIcon,
  ShieldIcon,
  LogOutIcon,
  AlertTriangleIcon,
  XIcon,
  MenuIcon,
  MailIcon,
  BarChart2,
  User,
} from 'lucide-react'
import { AuthContext } from '../../components/AuthContext.js'
import Footer from "../../components/Footer.js"

export default function PublicContact() {
  const { user, setUser, checkAuth, logout } = useContext(AuthContext)
  const [email, setEmail] = useState(user?.email || "")
  const [isLoading, setIsLoading] = useState(false)
  const [contactMessage, setContactMessage] = useState("")
  const [captchaInput, setCaptchaInput] = useState("")
  const CAPTCHA_ANSWER = "2"

  const API_BASE_URL =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_LOCAL_API
      : process.env.REACT_APP_PUBLIC_API

  const handleContactSubmit = async e => {
    e.preventDefault()
    const emailRegex = /\S+@\S+\.\S+/
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email")
      return
    }
    if (captchaInput.trim() !== CAPTCHA_ANSWER) {
      alert("Captcha incorrect, please try again.")
      return
    }
    setIsLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/contact`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email, message: contactMessage }),
      })
      if (response.ok) {
        alert("Message sent successfully.")
        setContactMessage("")
        setCaptchaInput("")
      } else {
        const errorData = await response.json()
        alert(`Error sending message: ${errorData.error}`)
      }
    }
    catch (error) {
      console.error("Error sending message:", error)
      alert("An error occurred while sending your message.")
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="flex justify-center w-full bg-[#eeeeee] min-h-[80vh] py-10">
        <div className="w-full max-w-2xl space-y-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p className="text-sm text-gray-600 mb-4">
              If you have questions, issues, or wants to reach out about anything, just say hi – we respond very quickly.
            </p>
            <form onSubmit={handleContactSubmit} className="max-w-2xl">
              <div className="space-y-4">
                <div>
                  <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    id="contactEmail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                  />
                </div>
                <div>
                  <label htmlFor="contactMessage" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea
                    id="contactMessage"
                    value={contactMessage}
                    onChange={e => setContactMessage(e.target.value)}
                    required
                    rows="6"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="captcha" className="block text-sm font-medium text-gray-700">What is 1 + 1?</label>
                  <input
                    type="text"
                    id="captcha"
                    value={captchaInput}
                    onChange={e => setCaptchaInput(e.target.value)}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-600 border border-gray-300 py-2 px-1"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading || contactMessage.length < 10}
                  className={`less_cool_button_with_shadow py-2 px-4 rounded-full block mx-auto cursor-pointer ${isLoading || contactMessage.length < 10 ? 'bg-gray-100' : ''}`}
                >
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer top_color={'#eeeeee'} />
    </>
  )
}
